/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { UserInfo } from './UserInfo'
import { Message } from './Message'
import { Uploader } from './Uploader'
import { Icon } from '@ys/components'
import { ShellEntry } from './ShellEntry'
import { Balance } from './Balance'
import { env } from '@/domain'
import styled from 'styled-components'
import AreaSelect from '@/components/areaSelect'

const HeaderToolbarStyle = styled.div`
  display: flex;
  height: 100%;
  justify-content: end;
  > .right {
    display: flex;
    /* margin-left: auto; */
    color: #666;

    > .AreaSelectContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      .anticon {
        font-size: 12px;
        margin: 0;
      }
    }

    .anticon {
      font-size: 16px;
      color: #999;
      margin: 4px;

      &.active {
        color: ${props => props.theme.primaryColor};
      }
    }

    > * {
      cursor: pointer;

      &:hover,
      &.ant-dropdown-open {
        background-color: #fafafa;
      }
    }
  }
`

export const HeaderToolbar = observer(function HeaderToolbar() {
  return (
    <HeaderToolbarStyle>
      <div className='right'>
      {/* {!env.isPersonal && <AreaSelect zoneList={env.zoneList} value={env.currentZoneKey} changeCallBack={env.changeZone} />} */}
        <Balance />
        <Uploader />
        {env.isFuture && (
          <ShellEntry
            icon={hover => (
              <Icon
                type={hover ? 'shell_intern_active' : 'shell'}
                style={{ color: '#999', fontSize: '19px' }}
              />
            )}
          />
        )}
        <Message />
        <UserInfo type='inside' />
      </div>
    </HeaderToolbarStyle>
  )
})

export { UserInfo } from './UserInfo'
export { Message } from './Message'
export { Uploader } from './Uploader'
export { ShellEntry } from './ShellEntry'
export { Balance } from './Balance'
