/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Icon } from '@ys/components'
import { formatByte } from '@ys/utils'
import { box, env } from '@/domain'
import { buryPoint } from '@/utils'
import { Tooltip } from 'antd'
import { notification } from '@/components'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const StyledLayout = styled.div`
  display: flex;
  align-items: center;

  > .space {
    overflow: hidden;
    white-space: nowrap;
    margin-left: 4px;
  }
`

const statusColorMap = {
  notfound: '#F5222D',
  success: '#9B9B9B',
  error: '#F5222D',
  warning: '#faad14'
}

type BoxData = {
  disk: {
    free: number
    used: number
    total: number
  }
  system: {
    load: {
      1: number
      5: number
      15: number
    }
  }
  time: number
}

type Props = {
  dropdownVisible?: boolean
}

export const Box = observer(function Box({ dropdownVisible }: Props) {
  const state = useLocalStore(() => ({
    diskTotal: 0,
    diskUsed: 0,
    tipVisible: false,
    hovered: false,
    setHovered(flag) {
      this.hovered = flag
    },
    setTipVisible(visible) {
      this.tipVisible = visible
    },
    get displayDiskTotal() {
      return formatByte(this.diskTotal)
    },
    get displayDiskUsed() {
      return formatByte(this.diskUsed)
    },
    get statusColor() {
      return statusColorMap[box.status]
    }
  }))

  // 展开下拉菜单，隐藏 tip
  useEffect(() => {
    if (dropdownVisible) {
      state.setTipVisible(false)
    }
  }, [dropdownVisible])

  useEffect(() => {
    if (!box.url) {
      return undefined
    }

    let disconnectTimer = null
    const eventSource = new EventSource(
      `${box.url}/api/box/ping?duration=3s&token=${
        box.token
      }&is_company=${!env.isPersonal}&company_id=${
        env?.company?.id || ''
      }&project_id=${env.project?.id || ''}`
    )
    eventSource.onmessage = e => {
      const data = JSON.parse(e.data) as BoxData
      state.diskTotal = data.disk.total
      state.diskUsed = data.disk.used
      let thresholdWarning =
        state.diskTotal != 0 && state.diskUsed / state.diskTotal > 0.9

      box.update({
        status: thresholdWarning ? 'warning' : 'success',
        diskUsed: state.diskUsed,
        diskTotal: state.diskTotal
      })

      disconnectTimer && clearTimeout(disconnectTimer)
      disconnectTimer = setTimeout(() => {
        box.update({
          status: 'error',
          diskUsed: 0,
          diskTotal: 0
        })
      }, 10000)
    }
    eventSource.onerror = () => {
      box.update({
        status: 'error',
        diskUsed: 0,
        diskTotal: 0
      })
    }

    return () => {
      eventSource && eventSource.close()
    }
  }, [box.url, env.project?.id])

  useEffect(() => {
    if (env.boxExhaust) {
      notification.warning({
        key: 'boxExhaust', // 保证只有一个消息框
        message: '请注意！',
        description:
          '文件存储已超过最大空间限制，超出部分将按量收费。'
      })
    }
  }, [box.exhaust])

  return (
    <Tooltip
      onVisibleChange={visible => state.setTipVisible(visible)}
      visible={state.tipVisible}
      title='存储空间'>
      <StyledLayout
        onMouseLeave={() => {
          state.setHovered(false)
        }}
        onMouseEnter={() => {
          state.setHovered(true)
        }}
        onClick={() => {
          buryPoint({
            category: '导航栏',
            action: '存储空间'
          })
        }}>
        <Icon
          type={
            dropdownVisible || state.hovered
              ? 'storage_active'
              : 'storage_default'
          }
          style={{ color: state.statusColor }}
        />
        <span className='space'>
          {state.displayDiskUsed} / {state.displayDiskTotal} {env.boxExhaust && <ExclamationCircleOutlined style={{ color: '#f9bf02' }}/>}
        </span>
      </StyledLayout>
    </Tooltip>
  )
})
