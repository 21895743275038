/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Layout } from 'antd'
import { companyList, currentUser, env } from '@/domain'
import { history, Http } from '@/utils'
import { Balance, ShellEntry, UserInfo } from '@/components/HeaderToolbar'
import { Box } from '@/components/HeaderToolbar/Uploader/Box'
import { Modal, Mask } from '@ys/components'
import { ApplyModal } from './ApplyModal'

const { Header: AntHeader } = Layout

const StyledHeader = styled(AntHeader)`
  display: flex;
  padding-left: 20px;
  padding-right: 0;

  > .left {
    display: flex;
    color: white;

    > .logo {
      cursor: pointer;

      img {
        height: 36px;
      }
    }

    > .company-name {
      margin-left: 32px;
      font-size: 16px;
    }
    > .point {
      cursor: pointer;
    }
  }

  > .right {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: #999;

    .anticon {
      font-size: 16px;
      margin: 4px;

      &.active {
        color: ${props => props.theme.primaryColor};
      }
    }

    > div {
      cursor: pointer;
      height: 100%;
      padding: 0 10px;

      &:hover {
        background: rgba(0, 88, 267, 0.15);
        color: white;
      }
    }

    > div.console {
      text-align: center;
      font-size: 19px;
      box-sizing: border-box;
      width: 44px;
    }
  }
`

type Props = {
  className?: string
}

export const Header = observer(function Header({ className = '' }: Props) {
  function backToHome() {
    history.push('/')
  }
  const [loading, setLoading] = useState(false)
  async function companyApply() {
    setLoading(true)
    try {
      await Http.post('/applyCompany/apply', {
        phone: currentUser.phone,
        user_name: currentUser.real_name
      })
      const { data } = await Http.get('applyCompany/applyInfo')
      const formData = {
        ...data,
        problems: data.apply_reason.pop()
      }
      formData &&
        (await Modal.show({
          showHeader: false,
          bodyStyle: { height: '690px' },
          width: 654,
          content: ({ onOk }) => <ApplyModal onOk={onOk} formData={formData} />,
          footer: null
        }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledHeader className={className}>
      <div className='left'>
        <div className='logo' onClick={backToHome}>
          <img
            src={
              env.isFuture
                ? require('@/assets/images/future_logo_text.svg')
                : env.custom.id
                ? require('@/assets/images/' + env.custom.logo)
                : require('@/assets/images/components/logo_text.svg')
            }
            alt='logo'
          />
        </div>
        {companyList?.current?.name ? (
          <div className='company-name'>{`${companyList?.current?.name}`}</div>
        ) : (
          <div className='point company-name'>
            {loading && <Mask.Spin />}
            <div className='point company-name' onClick={() => companyApply()}>
              免费升级到企业版
            </div>
          </div>
        )}
      </div>
      <div className='right'>
        <Balance rechargeVisible={false} />
        <Box />
        {env.isFuture && <ShellEntry />}
        <UserInfo type='portal' />
      </div>
    </StyledHeader>
  )
})
