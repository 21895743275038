import { observable, runInAction } from 'mobx'
import { Http } from '@/utils'
import { IncomingPageAware, OutcomingPageAware } from '../supports/page'
import { HardwareList } from './hardware'
import qs from 'qs'
import { message } from 'antd'

// <rootId, isReady>
const CACHEMAP: Map<string, boolean> = new Map()

class SignalServer {
  private _server: string = ''
  public async get(): Promise<string> {
    if (!this._server) {
      await fetch('/signal/address')
        .then(res => res.text())
        .then(v => {
          this._server = v
        })
    }
    return this._server
  }
}
const signalServer = new SignalServer()

export const HARDWARE_PLATFORM_ALL = 0
export const HARDWARE_PLATFORM_LINUX = 1
export const HARDWARE_PLATFORM_WINDOWS = 2

export const HARDWARE_PLATFORM_MAP = {
  0: '-',
  1: 'Linux',
  2: 'Windows'
}

export const HARDWARE_DISPLAY_UNKNOWN = 0
export const HARDWARE_DISPLAY_DESKTOP = 1
export const HARDWARE_DISPLAY_APPLICATION = 2

export const HARDWARE_DISPLAY_MAP = {
  0: '-',
  1: '桌面模式',
  2: '应用模式'
}

export const INSTANCE_STATUS_PENDING = 0
export const INSTANCE_STATUS_CREATED = 1
export const INSTANCE_STATUS_RUNNING = 2
export const INSTANCE_STATUSTERMINATED = 3

export const INSTANCE_STATUS_MAP = {
  PENDING: '创建中',
  CREATED: '已创建',
  RUNNING: '运行中',
  TERMINATED: '已结束'
}

export const CHARGE_TYPE_MAP = {
  1: '按量付费',
  2: '竞价付费'
}
export const SESSION_STATUS_ALL = 0
export const SESSION_STATUS_PENDING = 1
export const SESSION_STATUS_STARTING = 2
export const SESSION_STATUS_STARTED = 3
export const SESSION_STATUS_CLOSING = 4
export const SESSION_STATUS_CLOSED = 5

export const SESSION_STATUS_MAP = {
  1: '等待资源',
  2: '启动中',
  3: '已启动',
  4: '关闭中',
  5: '已关闭'
}

export const statusMapping = {
  等待资源: 'primary',
  启动中: 'primary',
  已启动: 'success',
  出错: 'error',
  关闭中: 'warn',
  已关闭: 'canceled'
}

export const SESSION_STATUS_BUTTON_LOADING = {
  READYING: '就绪中',
  READIED: '已就绪'
}

export class Hardware {
  @observable id: string
  @observable name: string
  @observable desc: string
  @observable charge_type: string
  @observable network_bandwidth: number
  @observable number_of_cpu: number
  @observable number_of_mem: number
  @observable number_of_gpu: number
  @observable cpu_model: number
  @observable gpu_model: number
  @observable price: {
    unit: number
    unit_desc: string
    unit_type: number
  }

  constructor(props: Partial<Hardware>) {
    Object.assign(this, props)
  }
}

export class Software {
  @observable id: string
  @observable name: string
  @observable desc: string
  @observable platform: number
  @observable display: number
  @observable icon: string
  @observable gpu_desired: boolean
  @observable price: {
    unit: number
    unit_desc: string
    unit_type: number
  }

  constructor(props: Partial<Software>) {
    Object.assign(this, props)
  }
}

export class Instance {
  @observable hardward: Hardware
  @observable software: Software
  @observable user_script: string
  @observable instanceStatus: string
  @observable start_time: string
  @observable end_time: string
  @observable create_time: string

  constructor(props: Partial<Instance>) {
    Object.assign(this, props)
  }
}

class RemoteApp {
  @observable id?: (string|null);
  @observable software_id?: (string|null);
  @observable desc?: (string|null);
  @observable base_url?: (string|null);
  @observable remote_app_name?: (string|null);
  @observable remote_app_dir?: (string|null);
  @observable remote_app_args?: (string|null);
  @observable remote_app_logo?: (string|null);
  @observable disable_gfx?: (boolean|null);
}

export class Session {
  @observable id: string
  @observable status: number
  @observable stream_url: string
  @observable start_time: string
  @observable end_time: string
  @observable exit_reason: string
  @observable create_time: string
  @observable is_auto_close: boolean
  @observable auto_close_time: { seconds: number; nanos: number }
  @observable time_left: string
  @observable remote_apps: RemoteApp[] = []

  constructor(props: Partial<Session>) {
    Object.assign(this, props)
  }
}

export class SessionUser {
  @observable real_name: string
  @observable phone: string
  @observable username: string
  @observable display_name: string

  constructor(props: Partial<SessionUser>) {
    Object.assign(this, props)
  }
}

export class ListHardwareRequest extends OutcomingPageAware {
  @observable number_of_cpu?: number = 0
  @observable number_of_mem?: number = 0
  @observable number_of_gpu?: number = 0
  @observable charge_type?: number
  @observable zone: string

  constructor(props?: Partial<ListHardwareRequest>) {
    super(props)
    Object.assign(this, props)
  }
}

class ListHardwareResponse extends IncomingPageAware {
  @observable items: Array<Hardware> = []

  constructor(props: any) {
    super(props)

    props.items.map((item: any) => {
      this.items.push(new Hardware(item))
    })
  }
}

class ListSoftwareRequest extends OutcomingPageAware {
  @observable name: string
  @observable platform: number
  @observable display: number
  @observable zone: string

  constructor(props?: Partial<ListSoftwareRequest>) {
    super(props)
    Object.assign(this, props)
  }
}

export class ListSoftwareResponse extends IncomingPageAware {
  @observable items: Array<Software> = []

  constructor(props: any) {
    super(props)

    props?.items.map((item: any) => {
      this.items.push(new Software(item))
    })
  }
}

export class StartSessionResponse {
  @observable session_id: string
  @observable webrtc_url: string
  @observable room_id: string
  @observable stream_url: string

  constructor(props: any) {
    Object.assign(this, props)
  }
}

export class ListSessionRequest extends OutcomingPageAware {
  @observable status: number = 0
  @observable user_id: string = ''
  @observable hardware_id: string = ''
  @observable software_id: string = ''
  @observable page_index: number = 1
  @observable page_size: number = 20
  @observable zone: string = ''

  constructor(props?: Partial<ListSessionRequest>) {
    super(props)
    Object.assign(this, props)
  }
}
export class pollRequestItem {
  @observable loading: boolean = false
  @observable timer: any = null
  @observable status: string
  @observable realStatus: string
  @observable roomId: string
  @observable refreshSession: boolean = false
  @observable timerList: Array<any> = []
}
export class ListSessionItem extends pollRequestItem {
  @observable session: Session
  @observable instance: Instance
  @observable user: SessionUser

  constructor(props: any) {
    super()
    this.session = new Session(props?.session)
    this.instance = new Instance(props?.instance)
    this.user = new SessionUser(props?.user)
    this.refresh(props.session)
  }
  clearSessionTimers() {
    this.timerList.forEach(item => {
      if (item.timer !== null) {
        clearInterval(item.timer)
      }
    })
    this.timerList = []
  }
  refresh(session) {
    
    this.status = session.status
    this.roomId = qs.parse(session?.stream_url.split('?')[1]).room_id

    const isReady = CACHEMAP.get(this.roomId)
    // 从之前缓存里面拿，room的状态，如果已经 ready，没必要再去请求
    if (isReady) {
      this.loading = false
      
      if (session.status === 3) {
        this.realStatus = 'READIED'
      } else if (session.status === 4 || session.status === 5) {
        this.realStatus = ''
        CACHEMAP.delete(this.roomId)
      }
     
      return
    }
    
    // 修复打开会话 button loading 导致的闪烁问题
    this.loading = session.status === 2 || session.status === 3

    if (session.status === 3) {     
      this.realStatus = 'READYING'
      
      runInAction(async () => {
        try {
          await fetch(
            `${await signalServer.get()}/ready?room_id=${this.roomId}`
          )
            .then(res => res.json())
            .then(data => {
              this.loading = !data.ready
              if (data.ready) {
                this['realStatus'] = 'READIED'
              }
              CACHEMAP.set(this.roomId, data.ready)
            })
            .then(() => {
              // window.localStorage.removeItem('room_id')
            })
        } catch (error) {
          console.log('error: ', error)
          return message.error(error)
          // this['realStatus'] = 'READIED'
        }
      })
    }
  }
}

export class ListSessionResponse extends IncomingPageAware {
  @observable items: Array<ListSessionItem> = []

  constructor(props?: any) {
    super(props)

    props?.items.map((item: any) => {
      this.items.push(new ListSessionItem(item))
    })
  }
}
export class RelatedHardWareResponse extends IncomingPageAware {
  @observable items: Array<ListSessionItem> = []

  constructor(props?: any) {
    super(props)

    props?.items.map((item: any) => {
      this.items.push(item)
    })
  }
}

const acquireAllItems = { page_size: 1000, page_index: 1 }

class VisBase {}

export default class Vis extends VisBase {
  @observable filterParams: {
    status: 0
    hardware_id: ''
    software_id: ''
    user_id: ''
    zone: ''
  }

  constructor(props?: Partial<VisBase>) {
    super()
  }

  async listHardware(
    request: ListHardwareRequest
  ): Promise<ListHardwareResponse> {
    return new ListHardwareResponse(
      (
        await Http.get('/vis_ibv/hardware', {
          params: {
            ...request
          }
        })
      ).data
    )
  }

  async listAllHardware(zoneId: string): Promise<Array<Hardware>> {
    return (
      await this.listHardware(
        new ListHardwareRequest({ ...acquireAllItems, zone: zoneId })
      )
    ).items
  }
  get filterquery() {
    return this.filterParams
  }

  setFilterParams(filterParams) {
    this.filterParams = filterParams
  }
  async listSoftware(
    request: ListSoftwareRequest
  ): Promise<ListSoftwareResponse> {
    return new ListSoftwareResponse(
      (
        await Http.get('/vis_ibv/software', {
          params: {
            ...request
          }
        })
      ).data
    )
  }

  async listAllSoftware(zoneId: string): Promise<Array<Software>> {
    return (
      await this.listSoftware(
        new ListSoftwareRequest({ ...acquireAllItems, zone: zoneId })
      )
    ).items
  }

  async startSession(props: {
    hardware_id: string
    software_id: string
    is_auto_close: boolean
    auto_close_time?: string
    gpu_confirm?: boolean
    charge_type?: string
    combo_id?: string
  }): Promise<StartSessionResponse> {
    return new StartSessionResponse(
      (
        await Http.post('/vis_ibv/session', {
          ...props
        })
      ).data
    )
  }

  async closeSession(session_id: string): Promise<any> {
    return (
      await Http.post('/vis_ibv/session/close', {
        session_id
      })
    ).data
  }

  async deleteSession(session_id: string): Promise<any> {
    return (
      await Http.delete('/vis_ibv/session', {
        data: { session_id }
      })
    ).data
  }
  async updateSession(
    session_id: string,
    autoClose: boolean,
    time?: string
  ): Promise<any> {
    return await Http.put(`/vis_ibv/session/${session_id}`, {
      is_auto_close: autoClose,
      auto_close_time: time
    })
  }

  async listSession(request: ListSessionRequest): Promise<ListSessionResponse> {
    return new ListSessionResponse(
      (
        await Http.get('/vis_ibv/session', {
          params: {
            ...request
          }
        })
      ).data
    )
  }

  async getRemoteAppUrl(session_id, app_name) {
    return (
      await Http.get('/vis_ibv/session/remoteAppUrl', {
        params: {
          session_id,
          app_name
        }
      })
    ).data?.url
  }
  async pollFetchRequest(roomId: string) {
    return await fetch(`${await signalServer.get()}/ready?room_id=${roomId}`)
      .then(res => res.json())
      .then(data => data)
  }

  async autoChoseHardware(id: string) {
    return (
      await Http.get('/vis_ibv/software/preset', {
        params: {
          software_id: id
        }
      })
    ).data
  }

  // 获取当前公司下，所有活的 3D 会话，产品经理定义：非关闭都算活的
  async getActiveSessions() {
    return (await Http.get('/vis_ibv/activeSessions')).data
  }

  async getComboList(zoneId: string, productId: string) {
    return (
      await Http.get('/vis_ibv/combo', {
        params: {
          zone: zoneId,
          product_id: productId
        }
      })
    ).data
  }

  async getTerminalLimit() {
    return (await Http.get('/vis_ibv/maxTerminalLimit')).data
  }
}

export const hardwareList = new HardwareList()
