/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { NewBoxHttp } from '@/domain/Box/NewBoxHttp'
import { env, box, currentUser } from '@/domain'
import qs from 'querystring'
import { Http } from '@/utils'
import { UPLOAD_CHUNK_SIZE } from '@/constant'
import { FileServer } from '.'

type DownloadParams = {
  paths: string[]
  types?: boolean[]
  sizes?: string[]
  base?: string
  bucket?: string
  sync_id?: string
  path_rewrite?: any
  boxUrl?: any
  isImage?: boolean
  url?: string // 分区url地址
}

export const newBoxServer: FileServer & {} = {

  compressStatus: ({
    url
  }: {
    url: string
  }) => {
    return NewBoxHttp(url).get('/filemanager/package_status', {disableErrorMessage: true})
  },

  compress: ({
    base_path,
    paths,
    target_path,
    bucket,
    project_id,
    url
  }: {
    base_path: string
    paths: string[]
    target_path: string
    bucket: string
    project_id?: string
    url: string
  }) => {
    return NewBoxHttp(url).post('/filemanager/package', {
      base_path,
      bucket,
      target_path,
      paths,
      project_id: project_id || env.project?.id
    }, {disableErrorMessage: true})
  },

  delete: ({
    paths,
    bucket,
    project_id,
    url
  }: {
    paths: string[]
    bucket: string
    project_id?: string
    url: string
  }) => {
    return Promise.all(
      paths.map(path =>
        NewBoxHttp(url).delete('/filemanager/rm', {
          params: {
            bucket,
            path,
            project_id: project_id || env.project?.id
          }
        })
      )
    )
  },

  list: async ({
    path = '.',
    sync_id,
    recursion = true,
    project_id,
    url,
    ...params
  }: {
    path?: string
    sync_id?: string
    recursion?: boolean
    folder_only?: boolean
    bucket?: string
    project_id?: string
    url: string
  }) => {
    return NewBoxHttp(url).get(`/filemanager${sync_id ? '/remote' : ''}/ls`, {
      params: {
        path,
        sync_id,
        project_id: project_id || env.project?.id,
        recursion,
        ...params
      }
    })
  },

  move: async ({
    items,
    bucket,
    url
  }: {
    items: [string, string][]
    bucket: string
    url: string
  }) => {
    await Promise.all(
      items.map(([srcPath, destPath]) =>
        NewBoxHttp(url).post('/filemanager/mv', {
          bucket,
          project_id: env.project?.id,
          src_path: srcPath,
          dest_path: destPath
        })
      )
    )
  },

  mkdir: ({
    path,
    bucket,
    project_id,
    url
  }: {
    path: string
    bucket: string
    project_id?: string
    url: string
  }) => {
    return NewBoxHttp(url).post('/filemanager/mkdir', {
      bucket,
      project_id: project_id || env.project?.id,
      path
    })
  },

  getContent: async ({
    sync_id,
    url,
    ...params
  }: {
    path: string
    offset: number
    length: number
    bucket?: string
    sync_id?: string
    url: string
  }) => {
    const {
      data: { content }
    } = await NewBoxHttp(url).get(`/filemanager${sync_id ? '/remote' : ''}/cat`, {
      params: {
        sync_id,
        project_id: env.project?.id,
        ...params
      }
    })

    return content
  },

  stat: ({
    sync_id,
    url,
    ...params
  }: {
    path: string
    bucket?: string
    sync_id?: string
    url: string
  }) => {
    return NewBoxHttp(url).get(`/filemanager${sync_id ? '/remote' : ''}/stat`, {
      params: {
        project_id: env.project?.id,
        sync_id,
        ...params
      }
    })
  },

  getFileUrl: async ({
    paths,
    sync_id,
    boxUrl,
    types,
    url,
    ...params
  }: DownloadParams) => {
    if (types && types.length === 1 && types[0]) {
      // if (!boxUrl) {
      //   boxUrl = box.url
      // }
      const names = paths.map(path => path.split('/').pop())

      !params.isImage &&
        Http.post('/filerecord/record', {
          type: 2,
          info: {
            storage_size: params.sizes[0],
            file_name: names[0],
            file_type: 1 || 0
          }
        })

      return `${url}/api/filemanager${
        sync_id ? '/remote' : ''
      }/single/download?path=${encodeURIComponent(paths[0])}&path_rewrite=${
        params.path_rewrite
      }&base=${params.base}&bucket=${params.bucket}&project_id=${
        env.project?.id
      }&token=${box.token}${sync_id ? `&sync_id=${sync_id}` : ''}`
    } else {
      const {
        data: { token, total_size }
      } = await NewBoxHttp(url).post(
        `/filemanager${sync_id ? '/remote' : ''}/download`,
        {
          ...(sync_id
            ? {
                path: paths[0],
                sync_id
              }
            : {
                paths
              }),
          project_id: env.project?.id,
          types: types,
          ...params
        }
      )

      return `${url}/api/filemanager${
        sync_id ? '/remote' : ''
      }/download?token=${token}&total_size=${total_size}`
    }
  },

  download: async (params: DownloadParams) => {
    const aEl = document.createElement('a')
    aEl.href = await newBoxServer.getFileUrl(params)
    document.body.appendChild(aEl)
    aEl.click()
    document.body.removeChild(aEl)
  },

  upload: async ({
    file,
    path,
    bucket,
    url,
    ...params
  }: {
    file: File
    path: string
    url: string
    bucket: string
  }) => {
    const {
      data: { upload_id }
    } = await NewBoxHttp(url).post(
      `/filemanager/pre-upload?${qs.stringify({
        path,
        file_size: file.size,
        bucket,
        project_id: env.project?.id,
        ...params
      })}`
    )

    async function uploadChunk(index) {
      const finish = UPLOAD_CHUNK_SIZE * (index + 1) > file.size
      const query = {
        upload_id,
        path,
        file_size: file.size,
        offset: UPLOAD_CHUNK_SIZE * index,
        slice_size: finish
          ? file.size - UPLOAD_CHUNK_SIZE * index
          : UPLOAD_CHUNK_SIZE,
        finish,
        bucket,
        project_id: env.project?.id,
        ...params
      }
      const formData = new FormData()
      formData.append(
        'slice',
        file.slice(UPLOAD_CHUNK_SIZE * index, UPLOAD_CHUNK_SIZE * (index + 1))
      )
      await NewBoxHttp(url).post(
        `/filemanager/upload?${qs.stringify(query)}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      if (!finish) {
        await uploadChunk(index + 1)
      }
    }

    await uploadChunk(0)
  }
}
