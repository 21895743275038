/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useState } from 'react'
import { Form, Input, message } from 'antd'
import { currentUser, env } from '@/domain'
import { validatePhone, validateEmail } from '@ys/utils'
import { Button, Modal } from '@ys/components'
import { EmailInput } from './EmailInput'
import { Style } from './style'
import { Http } from '@/utils'
import { cloudshell } from '@/domain/Shell'

interface ApplyFormProps {
  onCancel: () => void
  onOk: () => void
}

export const ApplyForm = ({ onCancel, onOk }: ApplyFormProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = async () => {
    setLoading(true)

    const o: Record<string, string> = form.getFieldsValue()
    const finalO = Object.entries(o).reduce((pre, [key, v]) => {
      pre[key] = v?.trim() || ''
      return pre
    }, {})

    try {
      await Http.post(
        '/cloudshell/apply',
        {
          user_name: currentUser.displayName,
          company_name: env.company?.name,
          ...finalO,
        },
        {
          formatErrorMessage: () => '提交申请失败',
        }
      )
      message.success('提交申请成功！')
      cloudshell.fetch()
      onOk()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Style>
      <div className='form'>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={onFinish}>
          <Form.Item
            label='姓名'
            name='real_name'
            required
            initialValue={
              currentUser.real_name || currentUser.display_user_name
            }
            rules={[{ required: true, message: '姓名不能为空' }]}>
            <Input placeholder='请输入姓名' autoFocus />
          </Form.Item>
          <Form.Item
            label='电话'
            name='phone'
            required
            initialValue={currentUser.phone}
            normalize={value => value.trim()}
            rules={[
              { required: true, message: '手机号不能为空' },
              {
                pattern: validatePhone.reg,
                message: '手机号格式不正确',
              },
            ]}>
            <Input autoComplete='new-password' placeholder='请输入手机号' />
          </Form.Item>
          <Form.Item
            label='E-mail'
            name='email'
            required
            normalize={value => value.trim()}
            rules={[
              { required: true, message: '邮箱不能为空' },
              {
                pattern: validateEmail.reg,
                message: '邮箱格式不正确',
              },
            ]}>
            <EmailInput
              initialValue={currentUser.email}
              onChange={value => {
                form.setFieldsValue({
                  email: value,
                })
              }}
            />
          </Form.Item>
          <Form.Item label='项目领域' name='project_domain'>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 2 }}
              placeholder='请输入项目领域'
            />
          </Form.Item>
          <Form.Item label='项目简介' name='project_desc'>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 2 }}
              placeholder='请输入项目简介'
            />
          </Form.Item>
          <Form.Item label='计算需求' name='resource_requirement'>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 2 }}
              placeholder='请输入计算需求'
            />
          </Form.Item>
        </Form>
      </div>
      <Modal.Footer
        className='footer'
        onCancel={onCancel}
        OkButton={
          <Button
            type='primary'
            loading={loading}
            onClick={() => form.submit()}>
            确定
          </Button>
        }
      />
    </Style>
  )
}
