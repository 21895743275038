/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { env, account } from '@/domain'
import { Icon } from '@ys/components'
import { accountBurnOutAlert } from './accountBurnOutAlert'
import { Tooltip } from 'antd'
import { Recharge } from './Recharge'
import { Hover } from '@/components'
import { history } from '@/utils'

const StyledLayout = styled.div`
  display: flex;
  align-items: center;

  > .balance {
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0 10px;

    > .text {
      margin-left: 6px;
    }
  }

  > .recharge {
  }
`

type Props = {
  rechargeVisible?: boolean
}

export const Balance = observer(function Balance({ rechargeVisible }: Props) {
  // 切换企业、工作空间时，account 会跟着改变
  useEffect(() => {
    accountBurnOutAlert()
  }, [account.id])

  const goToAccountPage = () => {
    if (env.isPersonal) {
      history.push('/account')
    } else {
      history.push('/company/account')
    }
  }

  return (
    env.isManager && (
      <Hover
        render={hovered => (
          <Tooltip
            title={
              env.accountIsFreeze
                ? '您的企业账户已经冻结，暂时无法提交作业和创建3D云应用会话，请联系平台客服。'
                : '账户余额'
            }>
            <StyledLayout>
              <div
                className='balance'
                onClick={() => goToAccountPage()}
                style={{ color: env.accountIsFreeze ? '#c0c0c0' : '' }}>
                <Icon
                  disabled={env.accountIsFreeze}
                  type={
                    hovered
                      ? 'account_setting_active'
                      : 'account_setting_default'
                  }
                />
                <span className='text'>
                  ¥{(account.account_balance / 100000).toFixed(2)}
                </span>
              </div>

              {rechargeVisible !== false && (
                <div className='recharge'>
                  <Recharge />
                </div>
              )}
            </StyledLayout>
          </Tooltip>
        )}
      />
    )
  )
})
