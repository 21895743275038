/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { Modal } from '@ys/components'

export const applying = () => {
  Modal.showConfirm({
    title: '正在申请中',
    content: '',
    CancelButton: null,
    okText: '关闭',
  })
}
