/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Dropdown, Menu } from 'antd'
import { observer, useLocalStore } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { Link } from 'react-router-dom'
import { currentUser, env } from '@/domain'
import { Modal, Icon } from '@ys/components'
import {
  CaretDownFilled,
  CaretUpFilled,
  ClockCircleOutlined
} from '@ant-design/icons'

import { Hover } from '@/components'
import { buryPoint } from '@/utils'
import { userServer, TroubleShootWorkOrderListService } from '@/server'
import { guide } from '@/utils'

const StyledMenu = styled(Menu)`
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 8px 20px;
    color: #666;

    a {
      color: #666;
    }
  }
  .ant-dropdown-menu-item {
    > * {
      display: flex;
      align-items: center;
    }

    .anticon.ysicon {
      margin-right: 8px;
      font-size: 16px;
      color: #666;

      &.hovered {
        color: ${props => props.theme.primaryColor};

        > span {
          background: white;
          display: inline-block;
        }
      }
    }
  }
`

const StyledUserInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;

  > .username {
    margin-left: 4px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
type Props = {
  type?: 'portal' | 'inside'
}
export const UserInfo = observer(function UserInfo({ type = 'inside' }: Props) {
  const state = useLocalStore(() => ({
    visible: false,
    setVisible(flag) {
      this.visible = flag
    },
    workOrderCount: 0,
    setWorkOrderCount(count) {
      this.workOrderCount = count
    }
  }))
  const { visible } = state

  useEffect(() => {
    userServer.current().then(({ data }) => {
      runInAction(() => {
        currentUser.update(data)
      })
    })
  }, [])

  useEffect(() => {
    if (!env.isPersonal && env.isManager) {
      guide.start('2')
    }
  }, [])

  useEffect(() => {
    const fetch = async function () {
      const { unresoled_count } =
        await TroubleShootWorkOrderListService.getTroubleShootCount({
          user_id: currentUser.id
        })
      state.setWorkOrderCount(unresoled_count)
    }
    visible && fetch()
  }, [visible])

  useEffect(() => {
    const fetch = async function () {
      const { unresoled_count } =
        await TroubleShootWorkOrderListService.getTroubleShootCount({
          user_id: currentUser.id
        })
      state.setWorkOrderCount(unresoled_count)
    }
    fetch()
  }, [])

  async function logout() {
    await Modal.showConfirm({
      title: '退出登录',
      content: '确认要退出登录吗？'
    })
    env.logout()
  }

  if (!currentUser.id) return null

  function onMenuClick(props) {
    const { key } = props
    buryPoint({
      category: '个人信息',
      action: {
        person: '个人设置',
        account: '账户管理',
        handbook: '用户手册',
        workorder: '待办工单',
        logout: '退出登录'
      }[key]
    })
    state.setVisible(false)
  }

  return (
    <Dropdown
      visible={state.visible}
      onVisibleChange={visible => state.setVisible(visible)}
      overlay={
        <StyledMenu onClick={onMenuClick}>
          {type === 'inside' && (
            <Menu.Item key='person'>
              <Hover
                render={hovered => (
                  <Link to='/personal-setting'>
                    <Icon
                      className={hovered ? 'hovered' : ''}
                      type='personal_setting_default'
                    />
                    <span>个人设置</span>
                  </Link>
                )}
              />
            </Menu.Item>
          )}
          {env.isCompanyManager && (
            <Menu.Item key='company'>
              <Hover
                render={hovered => (
                  <Link to='/company/workspaces'>
                    <Icon
                      className={hovered ? 'hovered' : ''}
                      type='company_mgt_default'
                    />
                    <span>企业管理</span>
                  </Link>
                )}
              />
            </Menu.Item>
          )}
          {type === 'inside' &&
            env.isPersonal && [
              <Menu.Item key='account'>
                <Hover
                  render={hovered => (
                    <Link to='/account'>
                      <Icon
                        className={hovered ? 'hovered' : ''}
                        type='account_setting_default'
                      />
                      <span>账户管理</span>
                    </Link>
                  )}
                />
              </Menu.Item>
            ]}
          <Menu.Item key='handbook'>
            <Hover
              render={hovered => (
                <a
                  target='_blank'
                  href={
                    env.isFuture
                      ? '/智算未来平台使用手册.pdf'
                      : ['4inCAv4EcJQ', '4cmZDZJr15Q'].includes(env.company?.id)
                      ? 'https://docs.yuansuan.cn/cloud-t3/'
                      : '/远算云平台使用手册.pdf'
                  }>
                  <Icon
                    className={hovered ? 'hovered' : ''}
                    type='handbook_default'
                  />
                  <span>用户手册</span>
                </a>
              )}
            />
          </Menu.Item>
          <Menu.Item key='workorder'>
            <Hover
              render={hovered => (
                <Link to='/workorder'>
                  <ClockCircleOutlined
                    style={{
                      fontSize: '16px',
                      marginRight: '8px',
                      color: hovered ? '#3182FF' : ''
                    }}
                  />
                  <span>待办工单({state.workOrderCount})</span>
                </Link>
              )}
            />
          </Menu.Item>
          <Menu.Item key='logout' onClick={logout}>
            <Hover
              render={hovered => (
                <div>
                  <Icon
                    className={hovered ? 'hovered' : ''}
                    type='logout_default'
                  />
                  <span>退出登录</span>
                </div>
              )}
            />
          </Menu.Item>
        </StyledMenu>
      }
      placement='bottomCenter'>
      <StyledUserInfo id='ys_header_user_menu'>
        <Icon
          type={
            state.visible ? 'global_setting_active' : 'global_setting_default'
          }
          className={`${state.visible ? 'active' : ''}`}>
          <span></span>
        </Icon>
        <div className='username' title={currentUser.displayName}>
          {currentUser.displayName || '--'}
        </div>
        {visible && (
          <CaretUpFilled style={{ marginLeft: 4, fontSize: '14px' }} />
        )}
        {!visible && (
          <CaretDownFilled style={{ marginLeft: 4, fontSize: '14px' }} />
        )}
      </StyledUserInfo>
    </Dropdown>
  )
})
