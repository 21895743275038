/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { List, Empty } from 'antd'
import { Link } from 'react-router-dom'
import { observer, useLocalStore } from 'mobx-react-lite'
import { format } from 'timeago.js'
import {
  UserAddOutlined,
  UserOutlined,
  UserDeleteOutlined,
  RobotOutlined,
  SmileOutlined,
  FrownOutlined,
  MehOutlined,
  MessageOutlined,
  CodeSandboxOutlined
} from '@ant-design/icons'
import { MessageType } from '@/domain/Messages/Message'
import { lastMessages } from '@/domain'
import { StyledPanel } from './style'

const iconMap = {
  [MessageType.CompanyInvitation]: (
    <UserAddOutlined style={{ color: '#52c41a' }} />
  ),
  [MessageType.ConfirmInvitation]: <UserOutlined />,
  [MessageType.CompanyExit]: (
    <UserDeleteOutlined style={{ color: '#f5222d' }} />
  ),
  [MessageType.JobRun]: <RobotOutlined />,
  [MessageType.JobComplete]: <SmileOutlined style={{ color: '#52c41a' }} />,
  [MessageType.JobFail]: <FrownOutlined style={{ color: '#f5222d' }} />,
  [MessageType.JobCancel]: <MehOutlined style={{ color: '#fa541c' }} />,
  [MessageType.Troubleshoot]: <MessageOutlined />,
  [MessageType.ProjectOverLimit]: (
    <CodeSandboxOutlined style={{ color: '#f5222d' }} />
  ),
  [MessageType.TroubleshootReplied]: <MessageOutlined />,
  [MessageType.TroubleshootClosed]: (
    <MessageOutlined style={{ color: '#52c41a' }} />
  )
}

export const Notifications = observer(function Notifications() {
  const { dataSource } = useLocalStore(() => ({
    get dataSource() {
      // TODO 获取通知
      return lastMessages.list.map(item => ({
        ...item,
        body: item.body,
        message: item.message,
        type: item.info.type,
        title: item.title
      }))
    }
  }))

  async function read(item) {
    await item.read()
    lastMessages.fetchUnreadCount()
  }

  function description(item) {
    switch (item.keyType) {
      case 'Message_ComputeJobEvent': {
        return (
          <Link to={`/job/${item.body.job_id}`}>
            <span style={{ paddingRight: 4 }}>{item.body.job_name}</span>
            {item.message}
          </Link>
        )
      }
      case 'Message_Troubleshoot': {
        return (
          <Link to={`/workorder-detail/${item.body.troubleshoot_id}`}>
            {item.message}
          </Link>
        )
      }

      default:
        return item.message
    }
  }

  return (
    <StyledPanel>
      <List
        bordered
        locale={{ emptyText: <Empty description='暂无消息通知' /> }}
        dataSource={dataSource}
        renderItem={item => (
          <List.Item key={item.message_id} className='item'>
            <List.Item.Meta
              avatar={iconMap[item.type]}
              title={
                <div className='title'>
                  <div>{item.title}</div>
                  <div className='time'>
                    {format(item.create_at.toString(), 'zh_CN')}
                  </div>
                  <div className='actions'>
                    {item.is_read ? (
                      <span className='isRead'>已读</span>
                    ) : (
                      <span className='notRead' onClick={() => read(item)}>
                        标为已读
                      </span>
                    )}
                  </div>
                </div>
              }
              description={description(item)}
            />
          </List.Item>
        )}
      />
    </StyledPanel>
  )
})
