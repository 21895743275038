/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import ReactDOM from 'react-dom'
import { Spin } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
import App from '@/App'
import Page500 from '@/pages/500'
import { env } from '@/domain'
import { StyledRoot } from './style'
import './index.css'
import { Modal, Drawer } from '@ys/components'
import { theme } from '@ys/utils/constant'
import { Provider } from '@/components'
import { guide } from '@/utils'

Modal.theme = theme
Modal.configProviderProps = { locale: zhCN }
Drawer.Wrapper = Provider

// ignore trivial rejection
window.addEventListener('unhandledrejection', event => {
  if (!event.reason) {
    event.preventDefault()
  }
})

const render = (Child: React.ReactNode) =>
  ReactDOM.render(
    <Provider>
      <StyledRoot id='styledRoot'>{Child}</StyledRoot>
    </Provider>,
    document.querySelector('#root')
  )

render(
  <div
    style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <Spin />
  </div>
)

env
  // init env && load youmeng script
  .initEnv()
  .then(() => env.init())
  .then(() => {
    if (
      !env.custom.id &&
      [
        '4hCkDLjiVJ1',
        '4hto9zidxxf',
        '4hrpyGGizA1',
        '4f9aWGn6EuY',
        '4evs5zeodN5',
        '4epgn7xYam5',
        '4dbPL4qXx6b',
        '48Bg5wxyUuG',
        '48Au3Y8uyaE',
        '48qaaGmSSVE',
        '46yj7xghby9',
        '46dohsVe3t3',
        '419eQtiH167',
        '3ZmTrFN86GC',
        '3T2gk36J3fL',
        '3S5JU8iyuzE'
      ].includes(env.company?.id)
    ) {
      // window.location.replace('https://kaiwu.yuansuan.cn')
      console.log('should redirect to kaiwu.yuansuan.cn')
    }
    guide.activate()

    render(
      <Provider>
        <StyledRoot id='styledRoot'>
          <App />
        </StyledRoot>
      </Provider>
    )
  })
  .catch(error => {
    const { status } = error.response || {}
    if (status !== 401) {
      render(<Page500 description={error.message} />)
    }
  })
