/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import { hot } from 'react-hot-loader/root'
import Loadable from 'react-loadable'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import PageLayout from '@/components/PageLayout'
import history from '@/utils/history'
import { PROJECT_PAGES, COMPANY_PAGES, NORMAL_PAGES } from './router'
import {
  HeaderToolbar,
  WebConfigComponent,
  SiderFooter,
  Notice,
  ProjectSelector,
  CompanyLayout,
  PopupNotice
} from '@/components'
import { BackTop } from 'antd'
import { RouterType } from '@/components/PageLayout/typing'
import { Modal } from '@ys/components'
import { env, noticeList } from './domain'
import { buryPoint } from './utils'

const Loading = ({ error }) => {
  if (error) {
    throw error
  }

  return <div>Loading...</div>
}

// 菜单点击埋点
function buryMenuPoint(props) {
  const { key, keyPath } = props
  const category = {
    workspace: '工作空间目录',
    company: '企业管理目录'
  }[keyPath[1]]
  // get target menu
  let target
  PROJECT_PAGES.every(router => {
    if (router.key === key || router.path === key) {
      target = router
      return false
    }

    return (router.children || []).every(item => {
      if (item.key === key || item.path === key) {
        target = item
        return false
      }
      return true
    })
  })
  let name
  if (target) {
    if (typeof target?.name === 'function') {
      name = target?.name()
    } else {
      name = target?.name
    }
  }

  if (name) {
    buryPoint({
      category,
      action: name
    })
  }
}

const ProjectRoute = ({ component: Component, render, path, ...rest }) => {
  const extraProps = {}
  if (env.isFuture) {
    extraProps.logo = [
      <img src={require('@/assets/images/future_logo_text.svg')} />,
      <img src={require('@/assets/images/future_logo.svg')} />
    ]
  } else if (env.custom.id) {
    extraProps.logo = [
      <img src={require('@/assets/images/' + env.custom.logo)} />,
      <img src={require('@/assets/images/' + env.custom.logoSmall)} />
    ]
  }

  useEffect(() => {
    noticeList.fetch().then(() => {
      const getPopNotice = () => {
        return noticeList.list.filter(
          n => n.priority === 0 && n.company_ids.indexOf(env.company.id) !== -1
        )
      }

      if (getPopNotice().length !== 0) {
        getPopNotice().map(() => {
          Modal.show({
            title: '公告信息提醒',
            footer: null,
            content: ({ onCancel, onOk }) => {
              return <PopupNotice onCancel={onCancel} onOk={onOk} />
            }
          })
        })
      }
    })
  }, [])

  return (
    <Route
      {...rest}
      path={path}
      render={matchProps => (
        <PageLayout
          {...extraProps}
          routers={PROJECT_PAGES}
          menuProps={{
            onClick: buryMenuPoint
          }}
          title={env.isKaiwu ? '开物' : ''}
          showBreadcrumb={true}
          history={history}
          HeaderToolbar={HeaderToolbar}
          SiderHeader={<ProjectSelector />}
          SiderFooter={<SiderFooter />}>
          <>
            <Notice />
            <BackTop style={{ right: 15, bottom: 130 }} />
            {Component && <Component {...matchProps} />}
            {render && render(matchProps)}
          </>
        </PageLayout>
      )}
    />
  )
}

const CompanyRoute = ({ component: Component, render, path, ...rest }) => {
  return (
    <Route
      {...rest}
      path={path}
      render={matchProps => (
        <CompanyLayout routers={COMPANY_PAGES}>
          <>
            <Notice />
            <BackTop style={{ right: 15, bottom: 130 }} />
            {Component && <Component {...matchProps} />}
            {render && render(matchProps)}
          </>
        </CompanyLayout>
      )}
    />
  )
}

const createRoute = CustomRoute => page => {
  const { visible } = page
  const LoadableComponent = Loadable({
    loader: page.component,
    loading: Loading
  })
  const NoPerm = Loadable({
    loader: () => import('./pages/403'),
    loading: Loading
  })

  const jobPathMap = {
    '^/standard-jobs': {
      destPath: '/jobs'
    },
    '^/jobs': {
      destPath: '/standard-jobs'
    },
    '^/job-creator': {
      destPath: '/standard-job-creator'
    },
    '^/standard-job-creator': {
      destPath: '/job-creator'
    },
    '^/standard-job/': {
      destPath: '/jobs'
    },
    '^/job/': {
      destPath: '/standard-jobs'
    },
    '^/jobset/': {
      destPath: '/standard-jobs'
    },
    // '^/files': {
    //   destPath: '/files'
    // }
  }

  return (
    <CustomRoute
      exact={page.exact}
      path={page.path}
      key='ys'
      render={props => {
        if (visible !== undefined  && page.path.includes('job')) {
          if ((typeof visible === 'function' && !visible()) || !visible) {
            let pathKey = Object.keys(jobPathMap).filter(key => {
              const pathReg = new RegExp(key)
              return pathReg.test(page.path)
            })[0]
            const jobPath = jobPathMap[pathKey]
            if (jobPath) {
              history.push(jobPath.destPath)
            } else {
              return <NoPerm {...props} />
            }
          }
        }
        return <LoadableComponent {...props} />
      }}
    />
  )
}

function createRouters(routers: RouterType[], RouteType: any = Route) {
  return routers.map(item => {
    return [
      item.path && createRoute(RouteType)(item),
      item.children &&
        createRouters(
          item.children.map(child => ({
            ...child,
            visible: item.visible
          })),
          RouteType
        )
    ]
  })
}

function App() {
  return (
    <Router history={history}>
      <WebConfigComponent />

      <Switch>
        {NORMAL_PAGES.map(createRoute(Route))}
        {createRouters(PROJECT_PAGES, ProjectRoute)}
        {createRouters(COMPANY_PAGES, CompanyRoute)}

        <Route exact path='/' render={() => <Redirect to='/dashboard' />} />
        <Route
          component={Loadable({
            loader: () => import('./pages/404'),
            loading: Loading
          })}
        />
      </Switch>
    </Router>
  )
}

export default hot(App)
