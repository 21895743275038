/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React from 'react'
import { List, Empty } from 'antd'
import { ChromeOutlined } from '@ant-design/icons'
import { Button, Modal } from '@ys/components'
import { observer } from 'mobx-react-lite'
import { format } from 'timeago.js'
import { lastInvitations } from '@/domain'
import { Invitation, InvitedUserType } from '@/domain/InvitationList/Invitation'
import { StyledPanel } from './style'

interface IProps {
  hideDropdown: () => void
}

export const Todo = observer(function Todo({ hideDropdown }: IProps) {
  async function accept(item: Invitation) {
    hideDropdown()
    await Modal.showConfirm({
      title: '接受邀请',
      content: '确认要接受邀请吗？',
    })
    await item.accept()
    await lastInvitations.fetchLast()
  }

  async function reject(item: Invitation) {
    hideDropdown()
    await Modal.showConfirm({
      title: '拒绝邀请',
      content: '确认要拒绝邀请吗？',
    })
    await item.reject()
    await lastInvitations.fetchLast()
  }

  return (
    <StyledPanel>
      <List
        bordered
        locale={{ emptyText: <Empty description='暂无未处理邀请' /> }}
        dataSource={lastInvitations.list}
        renderItem={item => (
          <List.Item key={item.id} className='item'>
            <List.Item.Meta
              avatar={<ChromeOutlined />}
              title={
                <div className='title'>
                  <div>企业邀请</div>
                  <div className='time'>
                    {format(item.create_time.toString(), 'zh_CN')}
                  </div>
                  <div className='actions'>
                    <Button type='link' onClick={() => accept(item)}>
                      接受
                    </Button>
                    <Button
                      type='link'
                      style={{ color: '#f5222d' }}
                      onClick={() => reject(item)}>
                      拒绝
                    </Button>
                  </div>
                </div>
              }
              description={
                item.is_admin === InvitedUserType.INVITE_IS_ADMIN
                  ? `${item.company_name}邀请你成为企业管理者`
                  : `${item.company_name}邀请你加入企业`
              }
            />
          </List.Item>
        )}
      />
    </StyledPanel>
  )
})
