/*!
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import { action, computed, observable, runInAction } from 'mobx'
import {
  currentUser,
  projectList,
  box,
  companyList,
  permList,
  etcdFeatures,
  lastInvitations,
  lastMessages,
  account,
  scList,
  env,
  visualConfig,
  visIBVConfig,
  noticeList,
  domainList
} from '@/domain'
import { userServer } from '@/server'
import { init as initVisToken } from './VisualHttp'
import { tracker } from '@ys/track-sdk'
import { Http, history, buryPoint } from '@/utils'
import { LAST_PROJECT_ID, CUSTOM_CONFIG } from '@/constant'
import { loadFavicon, loadScript, getUrlParams } from '@ys/utils'
import { softwareFreeTrial } from '@/domain'

class BaseEnv {
  @observable productId: string = ''
  @observable YSEnv: string = ''
  @observable isVisible: boolean = false
}

export class Env extends BaseEnv {
  // 个人用户
  @computed
  get isPersonal() {
    return companyList?.currentId === '0'
  }

  @computed
  get canSubmitJob() {
    return env.isPersonal || permList.check('job_submission')
  }

  // 混合云用户
  @computed
  get isMixed() {
    return !!companyList.current?.isMixed
  }

  // 是否是企业管理员/个人工作空间管理权限
  @computed
  get isManager(): boolean {
    return this.isPersonal || permList.check('company_management')
  }

  // 是否是企业管理员
  @computed
  get isCompanyManager(): boolean {
    return permList.check('company_management')
  }

  @computed
  get zoneMap() {
    return {
      'az-shanghai': '华东1',
      'az-wuxi': '华东2',
      'az-jinan': '华东3',
      'az-gansu': '西北1',
      'ys-box': '远算盒子'
    }
  }

  // domain地址列表
  @computed
  get DomainList() {
    return domainList?.result?.map(item => item.storage_domains[0]?.replace(/https?:\/\//i, '')) || []
  }

  // 区域下拉列表数据
  @computed
  get zoneList() {
    let selectData = []
    // const OMS_zone_list = this?.zone_list?.length
    //   ? JSON.parse(env.zone_list)
    //   : []
    // // 和oms的开关切换进行过滤筛选
    // // 如果OMS_zone_list没有数据则证明没有操作过oms区域的开关控制，那么默认无锡关闭, 反之则与OMS_zone_list列表进行兼容过滤
    //       const filterZoneList = !OMS_zone_list ? domainList.result.filter(item => item.zone !== 'az-wuxi') :  domainList.result.filter(item => {
    //       // 判断有没有该区域
    //       const findResult = OMS_zone_list.find(
    //         value => value.key === item.zone
    //       ) // find没找到是undfined
    //       if (!findResult) {
    //         return true
    //       }
    //       // 如果有，判断checked是否为false
    //       return findResult.checked
    //     })
    // 处理数据为下拉需要的数组数据，如果没有列表数据直接使用默认数据
    domainList?.zoneSelectData?.forEach(item => {
      selectData.push({
        name: item.zone,
        domain: item.domain,
        key: item.zone,
        sc_id: item.sc_id,
        desc: this.zoneMap[item.zone]
      })
    })
    return selectData
  }

  @observable zoneId: string = '' // 存储切换区域的domain
  @observable zoneKey: string = '' // 存储切换区域的标识
  @observable sc_id: string = '' // 存储切换区域的标识

  @action
  setZoneId = (id) => {
    this.zoneId = id
  }

  @action
  setZoneKey = (key) => {
    this.zoneKey = key
  }

  @action
  setScId = (id) => {
    this.sc_id = id
  }

  @action
  changeZone = (key, option) => {
    this.setZoneKey(key)
    this.setZoneId(option?.domain)
    this.setScId(option?.sc_id)
  }

  // 初始化区域的domain
  get initZoneId() {
    return this.zoneList[0]?.domain
  }

  // 初始化的zonekey az-shanghai az-jijin
  get initZoneKey() {
    return this.zoneList[0]?.key
  }

  // 初始化的超算id
  get initScId() {
    return this.zoneList[0]?.sc_id
  }

  // 当前区域的domain
  @computed
  get currentZoneId() {
    return this.zoneId || this.zoneKey ? this.zoneId : this.initZoneId
  }

  // 当前区域的key
  @computed
  get currentZoneKey() {
    return this.zoneKey ? this.zoneKey : this.initZoneKey
  }

  // 当前区域的超算id
  @computed
  get currentZoneScId() {
    return this.sc_id || this.zoneKey ? this.sc_id : this.initScId
  }

  // 是否是空间管理员
  @computed
  get isSpaceManager(): boolean {
    return (
      permList.check('company_management') &&
      !permList.check('project_management')
    )
  }

  // 当前工作空间
  @computed
  get project() {
    return projectList.current
  }

  // 当前企业
  @computed
  get company() {
    return companyList.current
  }

  // 文件管理分区列表
  @computed
  get zone_list() {
    return companyList.current?.zone_list
  }

  // 作业管理分区列表
  @computed
  get job_zone_list() {
    return companyList.current?.job_zone_list
  }

  // 作业管理分区列表
  @computed
  get company_id() {
    return companyList.current?.id
  }


  // 当前企业部门 ID
  @computed
  get department_id() {
    return currentUser.department_id
  }

  // 是否是智算未来平台
  get isFuture() {
    return this.productId === '3VGTYLv6FhW'
  }
  // 是否是开物平台
  get isKaiwu() {
    return this.productId === 'kaiwu'
  }

  get custom() {
    return CUSTOM_CONFIG
  }

  get shouldShowTrialModal() {
    const isKaiWu = location.hostname === 'kaiwu.yuansuan.cn'
    return env.isPersonal && isKaiWu
  }

  // 账户id
  get accountId() {
    return env.isPersonal ? currentUser?.account_id : env?.company?.account_id
  }

  // 当前账户是否冻结
  get accountFrozen() {
    if (this.isPersonal) {
      // 个人用户
      return account.account_balance <= 0
    } else {
      // 企业用户
      return account.account_balance <= -account.credit_quota
    }
  }

  // 当前账户是否有欠款
  get accountHasDebt() {
    if (this.isPersonal) {
      // 个人用户
      return account.account_balance < 0
    } else {
      // 企业用户
      return account.account_balance + account.credit_quota < 0
    }
  }

  @action
  update = (props: Partial<BaseEnv>) => {
    Object.assign(this, props)
  }

  @action
  logout = () => {
    currentUser.id = undefined

    // 清除最近一次企业/工作空间记录
    projectList.removeLastRecord()
    companyList.removeLastRecord()

    window.localStorage.removeItem('userId')
    window.location.replace('/api/sso/logout')
  }

  /**
   * 切换企业
   * 1. 更新盒子
   * 2. 更新当前用户权限列表
   * 3. 更新 etcdFeatures
   */
  changeCompany = async id => {
    try {
      await companyList.change(id)
    } catch (err) {
      // 企业进入失败，删除最近一次企业访问记录
      companyList.removeLastRecord()
      /** 隐藏企业切换功能 */
      // history.push('/company-chooser')
      history.push('/')
    }
    if (this.isPersonal) {
      currentUser.public_box_domain &&
        box.update({
          url: `https://${currentUser.public_box_domain}`
        })
      permList.update({
        list: []
      })
    } else {
      const domain = this?.company?.box?.box_domain
      domain &&
        box.update({
          url: `https://${domain}`
        })

      await permList.fetch()
    }
    await etcdFeatures.fetch()
    await account.fetch(this.accountId)
  }

  /**
   * 检查用户最近登录的工作空间
   */
  async checkProject() {
    await projectList.fetch()
    // 进入最近一次选择的工作空间
    let projectId = localStorage.getItem(LAST_PROJECT_ID)
    // 如果没有最近一次记录，则进入默认工作空间
    if (!projectId || !projectList.list.find(item => item.id === projectId)) {
      projectId = projectList.list.find(item => !!item.is_default)?.id
    }

    if (projectId) {
      await this.changeProject(projectId)
    } else {
      // 清除最近一次选择记录
      projectList.removeLastRecord()
      alert('工作空间不存在，请联系管理员')
    }
  }

  /**
   * 切换工作空间
   * 1. 更新 etcdFeatures
   * 2. 更新盒子
   * 3. 重新ping
   */
  changeProject = async id => {
    try {
      const { project } = await projectList.change(id)
      const domain =
        project?.box_domain ||
        (this.isPersonal
          ? currentUser.public_box_domain
          : this.company?.box?.box_domain)

      box.update({
        url: `https://${domain}`
      })
      await etcdFeatures.fetch()
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * 检查用户最近登录企业
   */
  async checkCompany() {
    // 优先获取企业列表，用于判断是否是个人用户/混合云用户
    await companyList.fetch()
    // 个人用户，还没有加入企业
    if (companyList.list.length === 0) {
      await this.changeCompany('0')
    } else {
      // 进入唯一企业
      await this.changeCompany(companyList.list[0]?.id)
    }

    return true
  }

  /**
   * 微信H5 年度报告 二维码
   * @param userId
   * @returns
   */

  async getWxQrCode(userId: string) {
    return await fetch(`/yearly2021/${userId}/qrcode`)
      .then(res => res.json())
      .then(data => data)
  }

  /**
   * 存储空间是否耗尽
   */
  @computed
  get boxExhaust(): boolean {
    return box.exhaust
  }

  /**
   * 当前用户/企业所能创建的最大工作空间数量
   */
  @computed
  get maxProjectThreshold(): number {
    if (this.isPersonal) {
      return currentUser.max_projects
    }
    return this.company.max_projects
  }

  // 当前企业账户是否被冻结
  get accountIsFreeze() {
    return account.is_freeze
  }

  @action
  async initEnv() {
    await Http.get('env').then(({ data }) => {
      this.update(data)
    })

    try {
      if (env.isFuture) {
        document.title = '智算未来'
        loadFavicon(require('@/assets/images/future_logo.svg'))
        // 加载友盟统计脚本
        // https://v1.cnzz.com/z_stat.php?id=1279373921&web_id=1279373921
        await loadScript('/future_stat.js')

        const _czc = window['_czc'] || []
        _czc.push(['_setAccount', '1279373921'])
      } else if (env.isKaiwu) {
        document.title = '开物平台'
        // loadFavicon(require('@/assets/images/future_logo.svg'))
      } else if (env.custom.id) {
        document.title = env.custom.title
        loadFavicon(require('@/assets/images/' + env.custom.favicon))
      } else {
        document.title = '远算云'
        // 加载友盟统计脚本
        // https://s4.cnzz.com/z_stat.php?id=1279373919&web_id=1279373919
        loadFavicon(require('@/assets/images/favicon.ico'))
        await loadScript('/cloud_stat.js')

        const _czc = window['_czc'] || []
        _czc.push(['_setAccount', '1279373919'])
      }

      const params = getUrlParams()
      buryPoint({
        category: '用户',
        action: '访问页面',
        label: `${params?.user_source || ''}-${
          params?.user_tag || ''
        }` as string
      })
    } catch (err) {
      console.error(err)
    }
  }

  @action
  async init() {
    await Promise.all([
      box.init(),
      domainList.fetch(),
      userServer.current().then(({ data }) => {
        runInAction(() => {
          currentUser.update(data)
        })

        tracker.setUserId(currentUser.id)

        return Promise.all([
          initVisToken(),
          this.checkCompany().then(async nextable => {
            // there is no need to enter project when pathname is cloud-shell
            if (['/cloud-shell'].includes(window.location.pathname)) {
              return
            } else if (nextable) {
              await this.checkProject()
            }
            // await visualConfig.fetch()
            await visIBVConfig.fetch()
          })
        ])
      })
    ])

    // init supercomputer list
    Http.get('/scs').then(({ data }) => {
      runInAction(() => {
        scList.update({
          list: data
        })
      })
    })

    // 目前只有个人用户可以申请免费试用
    this.isPersonal && (await softwareFreeTrial.fetch())

    // fetch notices
    noticeList.fetch()
    // fetch last invitations
    lastInvitations.fetchLast()
    // fetch last messages
    lastMessages.fetchLast()

    localStorage.setItem('userId', currentUser.id)
    // observer user change
    window.onstorage = async e => {
      if (e.key === 'userId') {
        window.location.replace('/')
      }
    }
  }
}
