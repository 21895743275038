/*
 * Copyright (C) 2016-present, Yuansuan.cn
 */

import React, { useEffect } from 'react'
import { apply, applying } from './utils'
import { Icon } from '@ys/components'
import styled from 'styled-components'
import { env } from '@/domain'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { cloudshell } from '@/domain/Shell'
import { CloudShellPermissionEnum } from '@/constant'

export const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  span.anticon.ysicon {
    font-size: 19px;
  }
`

const onClick = {
  [CloudShellPermissionEnum.NONE]: apply,
  [CloudShellPermissionEnum.PENDING]: applying,
  [CloudShellPermissionEnum.APPROVED]: () =>
    window.open('/cloud-shell', '_blank')
}

type Props = {
  icon?: (hover: boolean) => React.ReactNode
}

export const ShellEntry = observer(function ShellEntry({ icon }: Props) {
  const [hover, setHover] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    setLoading(true)
    cloudshell.init().finally(() => setLoading(false))
  }, [])

  return env.accountFrozen &&
    cloudshell.permission === CloudShellPermissionEnum.APPROVED ? (
    <Tooltip title='账户余额不足，请充值后使用'>
      <Style
        onMouseOver={() => {
          setHover(true)
        }}
        onMouseOut={() => {
          setHover(false)
        }}>
        {(icon && icon(hover)) || (
          <Icon disabled={loading} type={hover ? 'shell_active' : 'shell'} />
        )}
      </Style>
    </Tooltip>
  ) : (
    <Tooltip title='云端命令行'>
      <Style
        onMouseOver={() => {
          setHover(true)
        }}
        onMouseOut={() => {
          setHover(false)
        }}
        onClick={() => {
          if (loading) return
          onClick[cloudshell.permission]()
        }}>
        {(icon && icon(hover)) || (
          <Icon disabled={loading} type={hover ? 'shell_active' : 'shell'} />
        )}
      </Style>
    </Tooltip>
  )
})
